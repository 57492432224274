import headerList from "@/components/common/header-list.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import PaginationComponent from "../../../Global-Component/pagination.vue";
import { _ } from "vue-underscore";
export default {
  data: () => ({
    landingAirlinesList: [],
    searchText: "",
    showLoader: false,
    selectedFile: [],
    selected: "",
    selectedId: 0,
    selectedRows: [],
    sortColumn: "title",
    sortDirection: "asc",
    webUrl: process.env.VUE_APP_WEB_URL,
    paginate: {
      page_count: 1,
      selected: 1,
      per_page: 50,
      current_page: 1,
      last_page: 0,
    },
    recordTakeOptions: [25, 50, 100, 200],
  }),
  mixins: [paginationMixin],
  components: { "header-list": headerList, PaginationComponent },
  computed: {
    selectAllList() {
      // Check if the landingAirlinesList has any items
      if (this.landingAirlinesList.length > 0) {
        // Check if all items in the landingAirlinesList are selected
        return (
          _.where(this.landingAirlinesList, { isSelected: true }).length ===
          this.landingAirlinesList.length
        );
      }
      return false;
    },

    hasLandingAirlineShowPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "List" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "List" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "List" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasLandingAirlineAddPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Store" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Store" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Store" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasLandingAirlineDeletePermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Delete" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Delete" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Delete" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasLandingAirlineUpdatePermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Update" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Update" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Update" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },

    hasLandingAirlineHideShowPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Landing Airline Show|Hide" })
            .length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, {
                name: "Landing Airline Show|Hide",
              }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, {
                    name: "Landing Airline Show|Hide",
                  }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
  },
  methods: {
    applySort(columnName) {
      // Check if the clicked column is already the current sort column
      if (this.sortColumn === columnName) {
        // If it is, toggle the sort direction
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        // If it's a new sort column, update the sort column and set the direction to ascending
        this.sortColumn = columnName;
        this.sortDirection = "asc";
      }
      // Call the getAll() method to retrieve the sorted data
      this.getAll();
    },
    checkUncheckedList(event) {
      // Loop through each element in the landingAirlinesList
      this.landingAirlinesList.forEach((element) => {
        // Set the isSelected property of each element to the value of the event's target checkbox
        element.isSelected = event.target.checked;
      });
    },
    updateLandingAirlinesStatus(landingAirline, event) {
      let _vm = this;
      let _msg =
        // Create a confirmation message based on the current isDisplay value
        landingAirline.isDisplay == true
          ? "Are you sure you want to Deactivate this Landing Airline?"
          : "Are you sure you want to Activate this Landing Airline?";
      let _isDisplay = landingAirline.isDisplay == true ? 1 : 0;
      this.$dialog
        .confirm(_msg)
        .then(function () {
          // Send a POST request to update the status
          _vm.axios
            .post(
              "/landing-airline-update-status/id?id=" +
                landingAirline.id +
                "&isDisplay=" +
                _isDisplay
            )
            .then(function () {
              // On success, call the getAll() method to refresh the data
              _vm.getAll();
            })
            .catch(function () {
              // If an error occurs, revert the checkbox state to its original value
              document.getElementById(
                "faq-category-checkbox-" + landingAirline.id
              ).checked = landingAirline.isDisplay;
            });
        })
        .catch(function () {
          // If the confirmation is canceled, revert the checkbox state to its original value
          document.getElementById(
            "faq-category-checkbox-" + landingAirline.id
          ).checked = landingAirline.isDisplay;
        });
    },

    deleteRecord(id) {
      let _vm = this;
      let ids = [];
      // Check if a specific ID is selected
      if (this.selectedId > 0) {
        ids = [this.selectedId];
      } else {
        // Iterate over the landingAirlinesList to find selected elements
        this.landingAirlinesList.forEach((element) => {
          if (element.isSelected == true) {
            ids.push(element.id);
          }
        });
      }
      // Send a POST request to delete the records
      _vm.axios
        .post("/landing-airline-deletes", { ids: ids })
        .then(function (response) {
          // Refresh the list of records
          _vm.getAll();
          _vm.selectedId = 0;
        })
        .catch(function () {});
    },
    search() {
      this.getAll();
    },
    getQueryString() {
      // Create the base query string with pagination, record take, and sorting information
      let queryString =
        "?page=" +
        this.paginate.current_page +
        "&take=" +
        this.paginate.per_page +
        "&sort=" +
        this.sortColumn +
        "," +
        this.sortDirection;
      // Add the search text to the query string if it exists
      queryString +=
        this.searchText.length > 0 ? "&search=" + this.searchText : "";
      // Return the final query string
      return queryString;
    },
    getNextPage(page) {
      this.paginate.current_page = page;
      this.getAll();
    },
    updateRecordTake(newRecordTake) {
      this.paginate.per_page = newRecordTake;
      this.getAll();
    },
    getPageCount(dividend, divider) {
      let page = Math.floor(dividend / divider);
      return dividend % divider === 0 ? page : page + 1;
    },
    getAll() {
      let _vm = this;
      let queryString = this.getQueryString();
      _vm.showLoader = true;
      // Send a GET request to retrieve landing airline data
      this.axios
        .get("/landing-airline" + queryString)
        .then(function (response) {
          _vm.showLoader = false;
          let data = response.data.data.data;
          _vm.paginate.last_page = response.data.data.last_page;
          // Add a property 'isSelected' to each item in the data array and set it to false
          for (let index = 0; index < data.length; index++) {
            data[index]["isSelected"] = false;
          }
          _vm.landingAirlinesList = data;
          // Calculate the total page count for pagination
          _vm.paginate.page_count = _vm.getPageCount(
            response.data.data.total,
            response.data.data.per_page
          );
        })
        .catch(function (error) {
          // Handle any errors that occur during the API request
        });
    },
    handleCheck(e) {
      // Check if the target element is customCheck-1
      if (e.target.id === "customCheck-1") {
        if (e.target.checked) {
          // Select all rows and update isSelected property
          this.selectedRows = this.landingAirlinesList.map(
            (item) => "checkbox" + item.id
          );
          this.landingAirlinesList.forEach((element) => {
            element.isSelected = e.target.checked;
          });
        } else {
          // Clear selectedRows and update isSelected property
          this.selectedRows = [];
          this.landingAirlinesList.forEach((element) => {
            element.isSelected = false;
          });
        }
      } else {
        if (e.target.checked) {
          // Add the selected row to selectedRows
          this.selectedRows.push(e.target.id);
        } else {
          // Remove the unselected row from selectedRows
          this.selectedRows = this.selectedRows.filter((item) => {
            if (item !== e.target.id) {
              return item;
            }
          });
        }
      }
    },
  },
  mounted() {
    this.getAll();
  },
};
